import request from '@/utils/request.js'

export function getUserInfo() {
    return request({
        url: '/user/info',
        method: 'get'
    });
}

export function login(data) {
    return request({
        url: '/api/login',
        method: 'post',
        data,
        determineComponent: 'full'
    });
}