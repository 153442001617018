import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'flag-icon-css/css/flag-icons.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import { GBDMessage } from './utils/GBDMessage';

import { version } from '@/assets/version'

Vue.prototype.$GBDMessage = GBDMessage;

Vue.config.productionTip = false

// Function to check version
const checkVersion = () => {
  try {
    const currentVersion = version;
    const localVersion = localStorage.getItem('customer_app_version');

    if (localVersion && localVersion !== currentVersion) {
      // Version mismatch, force reload
      localStorage.setItem('customer_app_version', currentVersion);
      window.location.reload(true);
    } else {
      // Versions match or no local version found, set the local version
      localStorage.setItem('customer_app_version', currentVersion);
    }
  } catch (error) {
    console.error('Error checking app version:', error);
  }
};

checkVersion();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
