import Vue from 'vue'
import Vuex from 'vuex'
import { login } from "@/api/login"
import Cookies from 'js-cookie'
import router from '@/router'
import createPersistedState from 'vuex-persistedstate';
import { Message, MessageBox } from 'element-ui'; // 假设你使用 Element UI 进行错误提示
import { Notify } from 'vant'
import { GBDMessage } from '../utils/GBDMessage';
Vue.use(Vuex)

function format(params) {
  let obj = {}
  let arr = []
  for (let i = 0; i < Object.keys(params).length; i++) {
    let name = params[Object.keys(params)[i]].name
    let value = params[Object.keys(params)[i]].value
    arr.push({ name: name, value: value })
    obj[value.toString()] = {
      tocountry: params[Object.keys(params)[i]].tocountry,
      center: params[Object.keys(params)[i]].center
    }
  }
  obj.country = arr
  return obj;
}
function getInstitution(obj) {
  let res = [];
  for (let key in obj) {
    res.push(key);
  }
  return res
}
// 计算过期时间
function calculateDaysRemaining(timeString) {
  // 将时间字符串转换为时间对象
  const targetDate = new Date(timeString);
  // 获取当前时间
  const currentDate = new Date();
  // 计算时间差（毫秒数）
  const timeDifference = targetDate.getTime() - currentDate.getTime();
  // 将毫秒数转换为天数
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysRemaining;
}
function getInstitutionCountry(obj) {
  let res = {}
  for (let keyOne in obj) {
    let arr = []
    for (let keyTwo in obj[keyOne]) {
      for (let i = 0; i < obj[keyOne][keyTwo].tocountry.length; i++) {
        let o = {
          name: obj[keyOne][keyTwo].tocountry[i].name,
          url: obj[keyOne][keyTwo].tocountry[i].url
        }
        arr.push(o);
      }
    }
    res[keyOne] = arr
  }
  return res;
}

function toCountry(obj) {
  let arr1 = Object.keys(obj)
  let temporary = {}
  for (let i = 0; i < arr1.length; i++) {
    let arr2 = Object.keys(obj[arr1[i]])
    for (let j = 0; j < arr2.length; j++) {
      let countryArr = obj[arr1[i]][arr2[j]].tocountry
      for (let k = 0; k < countryArr.length; k++) {
        if (!temporary.hasOwnProperty(countryArr[k].value)) {
          temporary[countryArr[k].value] = countryArr[k].name
        }
      }
    }
  }
  return temporary;
}

function toCenter(obj) {
  let arr1 = Object.keys(obj)
  let temporary = {}
  for (let i = 0; i < arr1.length; i++) {
    let arr2 = Object.keys(obj[arr1[i]])
    for (let j = 0; j < arr2.length; j++) {
      let centerArr = obj[arr1[i]][arr2[j]].center
      for (let k = 0; k < centerArr.length; k++) {
        if (!temporary.hasOwnProperty(centerArr[k].value)) {
          temporary[centerArr[k].value] = centerArr[k].name
        }
      }
    }
  }
  return temporary;
}

function formatUserCountry(obj) {
  let res = [];
  for (let keyOne in obj) {
    if (obj.hasOwnProperty(keyOne)) {
      for (let keyTwo in obj[keyOne]) {
        let temporary = {}
        temporary.name = obj[keyOne][keyTwo].name
        temporary.value = obj[keyOne][keyTwo].value
        res.push(temporary);
      }
    }
  }
  return removeDuplicates(res);
}

// 数组对象去重
function removeDuplicates(arr) {
  let tempObj = {};
  return arr.filter(obj => {
    if (!tempObj[obj.value]) {
      tempObj[obj.value] = true;
      return true;
    }
    return false;
  });
}

export default new Vuex.Store({
  state: {
    user: {},
    TlsCC: [],
    BlsCC: [],
    AisCC: [],
    VfsCC: [],
    country: '',
    institutionList: [], // 机构列表
    expiration: 0,//过期时间
    institutionCountry: {},
    equipment: '',
    countryList: {}, // tocountry国家的value和name映射
    centerList: {}, // center的value和name映射
    userCountry: [],
    enableBookNum: '', // 主页开关
    enableAllNotice: '',
    pendingRequests: [],  // 使用数组存储请求
    loadingFull: false,
    loadingPart: false,
    tlsError:'',
    vfsError:'',
    aisError:'',
  },
  getters: {
    TlsCC: state => state.TlsCC,
    BlsCC: state => state.BlsCC,
    AisCC: state => state.AisCC,
    VfsCC: state => state.VfsCC,
  },
  mutations: {
    setLoadingFull(state, status) {
      state.loadingFull = status;
    },
    setLoadingPart(state, status) {
      state.loadingPart = status;
    },
    UPDATE_ENABLEBOOKNUM(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.enableBookNum = nueVal % 2;
    },
    UPDATE_ENABLEALLNOTICE(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.enableAllNotice = nueVal % 2;
    },
    UPDATE_USERCOUNTRY(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.userCountry = formatUserCountry(nueVal);
    },
    UPDATE_Country(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.countryList = toCountry(nueVal);
    },
    UPDATE_Center(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.centerList = toCenter(nueVal);
    },
    UPDATE_USER(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.user = nueVal;
    },
    UPDATE_EQUIPMENT(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.equipment = nueVal;
    },
    UPDATE_CC(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.institutionList = getInstitution(nueVal)
      state.institutionCountry = getInstitutionCountry(nueVal)
      if (state.institutionList.includes('Tls')) {
        state.TlsCC = format(nueVal.Tls);
      }
      if (state.institutionList.includes('Vfs')) {
        state.VfsCC = format(nueVal.Vfs);
      }
      if (state.institutionList.includes('Ais')) {
        state.AisCC = format(nueVal.Ais);
      }
      if (state.institutionList.includes('Bls')) {
        state.BlsCC = format(nueVal.Bls);
      }
    },
    UPDATE_REQUESTCOUNTRY(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.country = nueVal
    },
    UPDATE_EXPIRATION(state, nueVal) {
      // 修改 state 的 user 对象的 name 属性  
      state.expiration = calculateDaysRemaining(nueVal)
    },
  },
  actions: {
    // actions 中会调用 mutations 来修改 state  
    loginHandle({ state, commit }, data) {
      // 这里可以包含一些异步操作，比如 API 调用 
      login(data).then(res => {
        if (res.status == 200) {
          Cookies.set('customer-country', res.data.country[0], { expires: 365 })
          Cookies.set('customer-token', res.data.token, { expires: 365 })
          commit('UPDATE_REQUESTCOUNTRY', res.data.country[0]);

          commit('UPDATE_USER', res.data);
          commit('UPDATE_CC', res.data.supportInfo);
          commit('UPDATE_EXPIRATION', res.data.expiration);
          commit('UPDATE_Country', res.data.supportInfo);
          commit('UPDATE_Center', res.data.supportInfo);
          commit('UPDATE_USERCOUNTRY', res.data.supportInfo);
          commit('UPDATE_ENABLEBOOKNUM', res.data.enableBookNum);
          commit('UPDATE_ENABLEALLNOTICE', res.data.enableAllNotice);
          router.push({ path: '/index' })
        } else {
          GBDMessage({
            message: res.msg,
            duration: 3000,
            type: 'warning'
          });
        }
      })
    },
  },
  plugins: [
    // 使用 vuex-persistedstate 插件
    createPersistedState({
      key: 'Visaslot-customer',
    }),
  ],
})