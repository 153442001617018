<!-- ToastManager.vue -->
<template>
    <div class="toast-manager">
        <CustomToast v-for="(toast, index) in toasts" :key="index" :message="toast.message" :duration="toast.duration"
            :type="toast.type" @close="removeToast(index)" />
    </div>
</template>

<script>
import CustomToast from './CustomToast.vue';

export default {
    components: {
        CustomToast
    },
    data() {
        return {
            toasts: []
        };
    },
    methods: {
        addToast(message, duration = 3000, type = 'primary') {
            this.toasts.push({ message, duration, type });
        },
        removeToast(index) {
            this.toasts.splice(index, 1);
        }
    }
};
</script>

<style scoped>
.toast-manager {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
