// src/utils/configLoader.js
import axios from 'axios';

let config = null;

export async function loadConfig() {
    if (!config) {
        try {
            const response = await axios.get('/customer/config.json');
            config = response.data;
        } catch (error) {
            console.error('Failed to load config:', error);
            config = {}; // 返回一个空对象作为默认配置
        }
    }
    return config;
}

export function getConfig() {
    return config;
}
